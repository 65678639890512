import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Carousel, { Modal, ModalGateway } from "react-images"
import * as style from "../styles/gallery.module.scss"
import Title from "../components/Headings/Title"

const Gallery = ({ data }) => {
  const [modalVisibility, setModalVisibility] = useState(false)
  const [index, setIndex] = useState(0)

  const imgs = data.images.edges.map((elem, i) => {
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          setIndex(i)
          setModalVisibility(true)
        }}
        onKeyDown={() => {}}
        className="card shadow border-primary p-2"
        style={{
          backgroundColor: "#" + Math.random().toString(16).slice(-6) + "10",
        }}
      >
        <div className="card-img shadow">
          <GatsbyImage
            image={getImage({ ...elem.node.childImageSharp })}
            className={style.thumbnail}
          />
        </div>
      </div>
    )
  })

  const largesize = data.images.edges.map(elem => {
    return { source: elem.node.publicURL }
  })

  return (
    <Layout>
      <Seo title="Gallery" />
      <section className="gallery-section">
        <Title>Gallery... </Title>
        <div className="container py-5">
          <div className={style.grid}>{imgs}</div>
        </div>
      </section>
      <ModalGateway>
        {modalVisibility ? (
          <Modal onClose={() => setModalVisibility(!modalVisibility)}>
            <Carousel views={largesize} currentIndex={index} />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  )
}

export default Gallery

export const pageQuery = graphql`
  query {
    images: allFile(
      filter: { absolutePath: { regex: "/(diagrams)/.*.(png)/" } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              width: 300
              height: 200
              placeholder: BLURRED
              layout: CONSTRAINED
            )
          }
          publicURL
        }
      }
    }
  }
`
